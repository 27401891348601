require('intersection-observer');
import lozad from 'lozad';
import imagesLoaded from 'imagesloaded';

const closest = (el, targetClass) => {
  for (let item = el; item; item = item.parentElement) {
    if (item.classList.contains(targetClass)) {
      return item
    }
  }
}

const breakpoints = [
  {
    src: 'sp-small',
    width: 480,
  },
  {
    src: 'sp',
    width: 768,
  },
  {
    src: 'tablet',
    width: 1024,
  }
];

// get window size
const ww = document.documentElement.clientWidth;

export function lazyload() {
  const observer = lozad('.lozad', {
    rootMargin: '500px 0px', // syntax similar to that of CSS Margin
    threshold: 0.1, // ratio of element convergence
    load: function(el) {
      // return a breakpoint that qualifies
      const breakpoint = breakpoints.filter(function(breakpoint) {
        // return if item is larger than screen width
        // return if data-src for the breakpoint exists
        if(breakpoint.width >= ww && el.getAttribute(`data-src-${breakpoint.src}`)) {
          return(breakpoint.src);
        }
      }).shift(); // get the last item

      // default data-src
      let src = el.getAttribute('data-src');

      // if a breakpoint qualified, then change the attr
      if(breakpoint) src = el.getAttribute(`data-src-${breakpoint.src}`);

      if(src){
        // set the background-image css prop
        el.setAttribute('src', src);
        
        imagesLoaded(el, { background: true }, () => {
          el.classList.add('loaded');
          const parent = closest(el, 'js-lozad-parent');
          if(parent) {
            parent.classList.add('loaded');
          }
        });
      }
    }
  });

  const observerBg = lozad('.lozad-bg', {
    rootMargin: '500px 0px', // syntax similar to that of CSS Margin
    threshold: 0.1, // ratio of element convergence
    load: function(el) {
      // return a breakpoint that qualifies
      const breakpoint = breakpoints.filter(function(breakpoint) {
        // return if item is larger than screen width
        // return if data-src for the breakpoint exists
        if(breakpoint.width >= ww && el.getAttribute(`data-src-${breakpoint.src}`)) {
          return(breakpoint.src);
        }
      }).shift(); // get the last item

      // default data-src
      let src = el.getAttribute('data-src');

      // if a breakpoint qualified, then change the attr
      if(breakpoint) src = el.getAttribute(`data-src-${breakpoint.src}`);

      if(src){
        // set the background-image css prop
        el.style.backgroundImage = `url(${src})`; 

        imagesLoaded(el, { background: true }, () => {
          el.classList.add('loaded');
          const parent = closest(el, 'js-lozad-parent');
          if(parent) {
            parent.classList.add('loaded');
          }
        });
      }
    }
  });

  observer.observe();
  observerBg.observe();
  
  const loadFirst = document.querySelectorAll('.lozad-first');
  if(loadFirst) {
    for(let i = 0; i < loadFirst.length; i++) {
      const item = loadFirst[i]
      observer.triggerLoad(item);
    }
  }
}