export function navTrigger() {
  const trigger = document.getElementById('nav-trigger');
  const overlay = document.getElementById('nav-overlay');
  
  trigger.addEventListener('click', (e) => {
    e.preventDefault();
    document.body.classList.toggle('is-nav-active');
    return false;
  });

  overlay.addEventListener('click', (e) => {
    e.preventDefault();
    document.body.classList.remove('is-nav-active');
    return false;
  });
}