import skrollTop from 'skrollTop';
import './easings';
export function headerScrollNav() {

    let scrollTopArr = '';
    const scrollButton = document.querySelectorAll('.js-scroll-button');
    const scrollButtonParent = document.querySelectorAll('.js-scroll-button-parent');
    const scrollTop = document.querySelectorAll('.js-scroll-top');
    const minusEl = document.querySelector('.minus-el');

    const getOffset = (el) => {
      const box = el.getBoundingClientRect();
      return {
        top: box.top + window.pageYOffset - document.documentElement.clientTop,
        left: box.left + window.pageXOffset - document.documentElement.clientLeft
      }
    }

    const getHeight = (el) => {
      const styles = window.getComputedStyle(el);
      const height = el.offsetHeight;
      const borderTopWidth = parseFloat(styles.borderTopWidth);
      const borderBottomWidth = parseFloat(styles.borderBottomWidth);
      const paddingTop = parseFloat(styles.paddingTop);
      const paddingBottom = parseFloat(styles.paddingBottom);
      return height - borderBottomWidth - borderTopWidth - paddingTop - paddingBottom;
    }

    const closest = (node, selector) => {
      // IEや一部ブラウザでは.closet()が実装されていないので自前も用意
      return (node.closest || function(_selector) {
        do {
          // nodeとselectorがマッチしたら返す
          if ((node.matches || node.msMatchesSelector).call(node, _selector)) {
            return node;
          }
          // マッチしなかったら親要素を代入
          node = node.parentElement || node.parentNode;
        } while (node !== null && node.nodeType === 1);
    
        return null;
      }).call(node, selector);
    }

    const SCROLL_TOP_POSITION = function() {
      scrollTopArr = new Array;
      for (let i = 0; i < scrollTop.length; i++) {
        const target = scrollTop[i];
        const targetScrollOffset = getOffset(target);
        const minusElHeight = getHeight(minusEl);
        const targetScrollTop = targetScrollOffset.top - minusElHeight - 1;
        // const targetScrollBottom = targetScrollTop + getHeight(target) + 1;
        const targetScrollBottom = targetScrollTop + getHeight(target) + minusElHeight + 1;
        scrollTopArr[i] = [targetScrollTop, targetScrollBottom];
        console.log(minusElHeight);
      }
      
    }

    const SCROLL_POSITION_CHECK = function() {
      const scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      let check = false;
      for (let i = 0; i < scrollTopArr.length; i++) {
        const thisArr = scrollTopArr[i];
        const thisScrollTop = thisArr[0];
        const thisScrollBottom = thisArr[1];
        if(thisScrollTop < scTop && thisScrollBottom > scTop) {
          check = true;
          const thisScroll = scrollTop[i];
          const thisScrollName = thisScroll.dataset.scrolltop;
          const current = document.querySelector(`[data-scrollButton=${thisScrollName}]`);
          const container = closest(current, '.js-scroll-button-container');
          for (let i = 0; i < scrollButton.length; i++) {
            const _scrollButton = scrollButton[i];
            _scrollButton.classList.remove('is-current');
          }
          for (let i = 0; i < scrollButtonParent.length; i++) {
            const _scrollButtonParent = scrollButtonParent[i];
            _scrollButtonParent.classList.remove('is-current');
          }
          current.classList.add('is-current');
          if(container) {
            const scrollButtonParent = container.querySelector('.js-scroll-button-parent');
            scrollButtonParent.classList.add('is-current');
          }
        }
      }
      if(!check) {
        for (let i = 0; i < scrollButton.length; i++) {
          const _scrollButton = scrollButton[i];
          _scrollButton.classList.remove('is-current');
        }
      }
    }

    for (let i = 0; i < scrollButton.length; i++) {
      const _scrollButton = scrollButton[i];
      _scrollButton.addEventListener('click', (e) => {
        e.preventDefault();
        SCROLL_TOP_POSITION();
        SCROLL_POSITION_CHECK();
        const thisScrollName = _scrollButton.dataset.scrollbutton;
        const target = document.querySelector(`[data-scrollTop=${thisScrollName}]`);
        const targetOffset = getOffset(target);
        const minusElHeight = getHeight(minusEl);
        let targetOffsetTop = targetOffset.top - minusElHeight + 2;
        if(targetOffsetTop < 0) { targetOffsetTop = 0; }
        skrollTop.scrollTo({
          to: targetOffsetTop,
          easing: window.easings.easeInOutQuint,
          duration: 1200
        });
        document.body.classList.remove('is-nav-active');
      });
    }

    for (let i = 0; i < scrollButtonParent.length; i++) {
      const _scrollButtonParent = scrollButtonParent[i];
      _scrollButtonParent.addEventListener('click', (e) => {
        e.preventDefault();
        SCROLL_TOP_POSITION();
        SCROLL_POSITION_CHECK();
        const container = closest(_scrollButtonParent, '.js-scroll-button-container');
        const scrollButton = container.querySelector('.js-scroll-button');
        const thisScrollName = scrollButton.dataset.scrollbutton;
        const target = document.querySelector(`[data-scrollTop=${thisScrollName}]`);
        const targetOffset = getOffset(target);
        const minusElHeight = getHeight(minusEl);
        let targetOffsetTop = targetOffset.top - minusElHeight + 2;
        if(targetOffsetTop < 0) { targetOffsetTop = 0; }
        skrollTop.scrollTo({
          to: targetOffsetTop,
          easing: window.easings.easeInOutQuint,
          duration: 1200
        });
        document.body.classList.remove('is-nav-active');
      });
    }

    window.addEventListener('resize', () => {
      SCROLL_TOP_POSITION();
      SCROLL_POSITION_CHECK();
    });

    window.addEventListener('orientationchange', () => {
      SCROLL_TOP_POSITION();
      SCROLL_POSITION_CHECK();
    });

    window.addEventListener('scroll', SCROLL_POSITION_CHECK);

    FONTPLUS.attachCompleteEvent(() => {
      SCROLL_TOP_POSITION();
      SCROLL_POSITION_CHECK();
    });

    SCROLL_TOP_POSITION();
    SCROLL_POSITION_CHECK();

}