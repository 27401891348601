export function scrollRelease() {
  const container = document.querySelectorAll('.js-split-container');

  function getOffset (el) {
    const box = el.getBoundingClientRect();
    return {
      top: box.top + window.pageYOffset - document.documentElement.clientTop,
      left: box.left + window.pageXOffset - document.documentElement.clientLeft
    }
  }

  // Native
  function getHeight(el) {
    const styles = window.getComputedStyle(el);
    const height = el.offsetHeight;
    const borderTopWidth = parseFloat(styles.borderTopWidth);
    const borderBottomWidth = parseFloat(styles.borderBottomWidth);
    const paddingTop = parseFloat(styles.paddingTop);
    const paddingBottom = parseFloat(styles.paddingBottom);
    return height - borderBottomWidth - borderTopWidth - paddingTop - paddingBottom;
  }

  for (let i = 0; i < container.length; i++) {
    const _container = container[i];
    const fixedElement = _container.getElementsByClassName('js-split-fixed')[0];
    const scrollElement = _container.getElementsByClassName('js-split-scroll')[0];

    let wh = '';
    let fixedElementHeight = '';
    let fixedElementOffset = '';
    let fixedElementOffsetTop = '';
    let fixedElementOffsetBottom = '';
    
    let scrollElementHeight = '';
    let scrollElementOffset = '';
    let scrollElementOffsetTop = '';
    let scrollElementOffsetBottom = '';

    const setSize = () => {
      wh = window.document.documentElement.clientHeight;

      fixedElementHeight = fixedElement.clientHeight;
      fixedElementOffset = getOffset(fixedElement);
      fixedElementOffsetTop = fixedElementOffset.top;
      fixedElementOffsetBottom = fixedElementOffsetTop - fixedElementHeight;

      scrollElementHeight = scrollElement.clientHeight;
      scrollElementOffset = getOffset(scrollElement);
      scrollElementOffsetTop = scrollElementOffset.top;
      scrollElementOffsetBottom = scrollElementOffsetTop + scrollElementHeight;
    }

    const scroller = () => {
      if(scrollElementHeight <= fixedElementHeight) {
        _container.classList.add('is-scroll-release', 'is-scroll-fixed');
        return;
      }
      const scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const scBottom = scTop + wh;
      if(scrollElementOffsetTop <= scTop) {
        _container.classList.add('is-scroll-fixed');
        if(scrollElementOffsetBottom <= scBottom) {
          _container.classList.add('is-scroll-release');
        } else {
          _container.classList.remove('is-scroll-release');
        }
      } else {
        _container.classList.remove('is-scroll-fixed');
      }
    }

    window.addEventListener('resize', () => {
      setSize();
      scroller();
    });

    window.addEventListener('scroll', scroller);

    FONTPLUS.attachCompleteEvent(() => {
      setSize();
      scroller();
    });

    setSize();
    scroller();

  };
}