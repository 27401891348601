export function headerFixedNav() {
  const baseElement = document.getElementById('header');
  const fixedElement = document.getElementById('header-container');

  function getOffset (el) {
    const box = el.getBoundingClientRect();
    return {
      top: box.top + window.pageYOffset - document.documentElement.clientTop,
      left: box.left + window.pageXOffset - document.documentElement.clientLeft
    }
  }

  let baseElementOffsetTop = '';

  const setSize = () => {
    const baseElementOffset = getOffset(baseElement);
    baseElementOffsetTop = baseElementOffset.top;
  }

  const scroller = () => {
    const scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    if(baseElementOffsetTop <= scTop) {
      fixedElement.classList.add('is-fixed');
    } else {
      fixedElement.classList.remove('is-fixed');
    }
  }

  window.addEventListener('resize', () => {
    setSize();
    scroller();
  });

  window.addEventListener('scroll', scroller);

  FONTPLUS.attachCompleteEvent(() => {
    setSize();
    scroller();
  });

  setSize();
  scroller();
}