// Stylesheets
import './assets/scss/site.scss';

// Modules
import {fixSvgIntrinsicSizing} from './assets/modules/fixSvgIntrinsicSizing';
import {hover} from './assets/modules/hover';
import {lazyload} from './assets/modules/lazyload';
import {navTrigger} from './assets/modules/navTrigger';
import {headerFixedNav} from './assets/modules/headerFixedNav';
import {headerScrollNav} from './assets/modules/headerScrollNav';
import {scrollRelease} from './assets/modules/scrollRelease';

fixSvgIntrinsicSizing();
hover();
lazyload();
navTrigger();
headerFixedNav();
headerScrollNav();
scrollRelease();